<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">保险记录</div>
				<div class="header-button">
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/car-center/car-insurance/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					showSelection
					autoWidth
					@selection-change="onSelectionChange"
				>
					<template #type="{ value }">
						<span>{{ mapDict('type', value) }}</span>
					</template>
					<template #annexesArr="{ row }">
						<span v-if="row.annexesArr.length" class="cursor">
							<el-link v-if="row.images.length" type="primary" @click="onViewImage(row.images)">
								图片({{ row.images.length }})
							</el-link>
							<span v-if="row.images.length && row.files.length" style="padding: 0 10px">|</span>
							<el-popover placement="right" :width="200" trigger="click">
								<template #reference>
									<el-link v-if="row.files.length" type="primary">
										文件({{ row.files.length }})
									</el-link>
								</template>
								<div v-for="(file, index) in row.files" :key="index" style="padding: 10px">
									<el-row
										type="flex"
										align="center"
										class="click"
										style="align-items: center"
										@click="onOpenFile(file.url)"
									>
										<el-icon><Document /></el-icon>
										{{ file.fileName }}
									</el-row>
								</div>
							</el-popover>
						</span>
						<span v-else>--</span>
					</template>
					<template #operation="{ row }">
						<span
							class="click"
							@click="onDelete(row)"
							v-permission="'pc/car-center/car-insurance/delete'"
						>
							删除
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<el-image-viewer
			v-if="visible"
			:url-list="srcList"
			hide-on-click-modal
			@close="setVisible(false)"
		/>
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'InsuranceRecords',
})
</script>

<script setup name="InsuranceRecords">
import { inject, onActivated, ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { getStore } from '@/utils/store'
import { setting, columns } from './config'
import { useState, useFetch, useFilter } from '@/utils/hooks.js'
import { ElMessageBox } from 'element-plus'
import {
	companySelect,
	channelSelect,
	carInsuranceList,
	deleteInsurance,
	exportCarInsurance,
	maintenanceSelect,
} from '@/api/vehicle.js'
import { handleExport } from '@/utils/util'

const $message = inject('$message')

const settingRef = ref(setting)
const userInfo = getStore({ name: 'userInfo' })

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const [visible, setVisible] = useState(false)

const [loading, setLoading] = useState(false)
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [page, setPage] = useState({ current: 1, size: 20 })

const srcList = ref([])

async function fetchList() {
	const payload = { ...params.value, page: page.value }
	const service = carInsuranceList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		data.records = data.records.map(item => {
			const images = (item.annexesArr || []).filter(val => {
				const fileType = val && val.split('.').pop().toLocaleUpperCase()
				return ['BMP', 'JPG', 'JPEG', 'PNG', 'TIF', 'GIF'].includes(fileType)
			})
			const files = (item.annexesArr || [])
				.filter(val => {
					const fileType = val && val.split('.').pop().toLocaleUpperCase()
					return !['BMP', 'JPG', 'JPEG', 'PNG', 'TIF', 'GIF'].includes(fileType)
				})
				.map(file => {
					let disFile = file.split('/')
					return {
						url: file,
						fileName: disFile[disFile.length - 1],
						suffix: file.split('.').pop().toLocaleUpperCase(),
					}
				})
			return {
				...item,
				images,
				files,
			}
		})
		setTable(data.records)
		setTotal(data.total)
	}
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
async function onExport() {
	setLoading(true)
	try {
		const res = await exportCarInsurance(params.value)
		if (res) handleExport(res, '保险记录')
	} catch (err) {
		$message.error(err.msg || err.message)
	} finally {
		setLoading(false)
	}
}
function onDelete(row) {
	const { id } = row
	ElMessageBox.confirm('是否确认删除当前保险记录？', '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning',
	})
		.then(async () => {
			const { err } = await fetchData(deleteInsurance, id)
			if (err) return $message.error(err.message)
			$message.success('操作成功')
			fetchList()
		})
		.catch(() => {})
}
async function fetchSelect() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [chanRes, compRes, driverSupportRes] = await Promise.all([
		useFilter(() => channelSelect({ isPermission: true })),
		useFilter(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
		useFilter(() => maintenanceSelect('DriverService', arr, { isAll: true }), {
			name: 'realName',
			code: 'id',
		}),
	])
	if (!driverSupportRes.err) {
		const driverSupport = findItem('driverSupportId')
		driverSupport.options = driverSupportRes.data
	}

	if (!compRes.err) {
		const company = findItem('companyId')
		company.options = compRes.data
	}
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}
function onSelectionChange(val) {}
function onChangeFilter(event) {
	console.log('event', event)
	const params = { ...event }
	if (Array.isArray(params.companyId) && !params.companyId.length) {
		delete params.companyId
	}
	if (Array.isArray(params.endDate) && params.endDate.length) {
		const [start, end] = params.endDate
		params.endDateBegin = start
		params.endDateEnd = end
	} else {
		delete params.endDateBegin
		delete params.endDateEnd
	}
	setParams(params)
	fetchList()
}
function onViewImage(images) {
	setVisible(true)
	srcList.value = images
}
function onOpenFile(url) {
	window.open(url, '_blank')
	// const iframe = document.createElement('iframe')
	// iframe.style.display = 'none'
	// iframe.style.height = 0
	// iframe.src = url
	// document.body.appendChild(iframe)
	// setTimeout(() => {
	// 	iframe.remove()
	// }, 1000)
}

async function fetchDict() {
	setDict({
		type: [
			{ code: 10, name: '交强险' },
			{ code: 20, name: '商业险' },
		],
	})
}
function init() {
	fetchDict()
	fetchList()
	fetchSelect()
	const route = useRoute()
	const query = route.query
	if (Object.keys(query).length > 0) {
		params.value.keyword = query.keyword || ''
		params.value.status = query.status || null
		params.value.companyId = query?.companyIds?.split(',') || []
		const [start, end] = query?.statementDate?.split(',') || []
		params.value.statementDate =
			(query?.statementDate && [`${start} 00:00:00`, `${end ? end : start} 23:59:59`]) || null
		const ins = getCurrentInstance()
		ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
	}
}
onActivated(() => {
	init()
})
onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
